export enum SourceLocationSection {
    SL_INFORMATION = 'slInformation',
    PLANT_CODE = 'plantCode',
    CV_CODE = 'cvCode',
    PRODUCTION_SOURCE = 'productionSource'
}

export enum SourceLocationInformationProps {
    SOURCE_LOCATION_CODE = 'sourceLocationCode',
    SOURCE_LOCATION_NAME = 'sourceLocationName',
    BENEFICIARY_NAME = 'beneficiaryName',
    SL_STATUS = 'sourceLocationStatus',
    FTP_BATCH_CODE = 'ftpBatchCode',
    CREATE_PTSL_FILE = 'createPTSLfile',
    GL_ACCOUNT_NO = 'glAccountNo',
    PRODUCTS_TYPE = 'productsType',
    SL_BANK_ACCOUNTS = 'sourceLocationBankAccounts',
    BANK_ID = 'bankAccountId',
    BANK_CODE = 'bankCode',
    BANK_NAME = 'bankName',
    BANK_CURRENCY_CODE = 'currencyCode',
    BANK_CURRENCY_NAME = 'currencyName',
    BANK_ACCOUNT_CODE = 'bankAccountCode',
    BANK_IBAN_CODE = 'ibanCode',
    BANK_BIC_CODE = 'bicCode',
    BANK_COUNTRY_NAME = 'countryName'
}

export enum sourceLocationBankAccountsProps {
    BANK_ID = 'bankAccountId',
    BANK_CODE = 'bankCode',
    BANK_NAME = 'bankName',
    BANK_CURRENCY_CODE = 'currencyCode',
    BANK_CURRENCY_NAME = 'currencyName',
    BANK_ACCOUNT_CODE = 'bankAccountCode',
    BANK_IBAN_CODE = 'ibanCode',
    BANK_BIC_CODE = 'bicCode',
    BANK_COUNTRY_NAME = 'countryName'
}

export enum DistributionBodiesProps {
    ALL_SOURCE_LOCATION = 'allSourceLocations',
    CV_CODE = 'cvCodes',
    LIST_EDM = 'listOfEDM',
    LIST_FTC = 'ftcs'
}

export enum SourceLocationInformationFilterProps {
    SOURCE_LOCATION_CODE = 'sourceLocationCode',
    SOURCE_LOCATION_NAME = 'sourceLocationName',
    BENEFICIARY_NAME = 'beneficiaryName'
}

export enum SourceLocationTestIds {
    HEADER = 'source-location__header',
    FILTER_TOOLBAR = 'source-location__filter-toolbar',
    TABS = 'source-location__tabs',
    TABLE = 'source-location__table',
}