import { NextRouteProps } from 'app/core/enums/route.enum';
import { NextRouteItem } from 'app/core/types/route.type';
import { SourceLocationConstant } from './constant/source-location.constant';
import { FormSectionProps } from '@components/organisms/layout/enum/form-section.enum';
import { SourceLocationSection } from './enums/source-location.enum';
import { BaseRouteConfig } from 'app/core/constants/base.route.config';
import { GeneralMaintenanceRoutingConfig } from '@pages/general-maintenance/general-maintenance.route.config';
import { NextPermissionId } from 'app/core/enums/permission.enum';
import { NavItemId } from 'app/shared/enums/navigation.enum';

export class SourceLocationRouteConfig {
    static readonly mainPath = 'source-location';

    static readonly listConfig: NextRouteItem = {
        [NextRouteProps.ID]: NavItemId.SOURCE_LOCATION,
        [NextRouteProps.LABEL]: 'SOURCE_LOCATION.TITLE',
        [NextRouteProps.PATH]: SourceLocationRouteConfig.mainPath,
        [NextRouteProps.PARENT]: GeneralMaintenanceRoutingConfig.mainRoute,
        [NextRouteProps.PERMISSION]: [NextPermissionId.SOURCE_LOCATIONS]
    };

    //SL Information
    static readonly slInformation: NextRouteItem = {
        [NextRouteProps.PATH]: SourceLocationConstant.sectionConfig[SourceLocationSection.SL_INFORMATION][FormSectionProps.PATH],
        [NextRouteProps.LABEL]: SourceLocationConstant.sectionConfig[SourceLocationSection.SL_INFORMATION][FormSectionProps.LABEL_KEY],
        [NextRouteProps.PARENT]: SourceLocationRouteConfig.listConfig,
    }

    static readonly slInformationConfig: NextRouteItem = {
        [NextRouteProps.PATH]: SourceLocationRouteConfig.mainPath,
        [NextRouteProps.PARENT]: SourceLocationRouteConfig.slInformation,
    };

    static readonly sourceLocationTabStep: NextRouteItem = {
        ...BaseRouteConfig.sectionBase,
        [NextRouteProps.PARENT]: SourceLocationRouteConfig.slInformationConfig
    };
}