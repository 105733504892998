import { FormSectionProps } from '@components/organisms/layout/enum/form-section.enum';
import { SourceLocationInformationFilterProps, SourceLocationInformationProps, SourceLocationSection, sourceLocationBankAccountsProps } from '../enums/source-location.enum';
import { ColumnSize, TableField, TemplateType } from '@components/organisms/next-table/next-table.type';
import { GenericTableColumnProps, TableFieldProps } from '@components/organisms/next-table/enums/next-table.enum';
import { LinkButtonPosition } from '@components/atoms/buttons/components/link-button/link-button.enum';
import { CommonBooleanLabelPipe } from '@pipes/boolean-label.pipe';
import { DataTitleConfig } from '@components/atoms/visual/components/data-title/data-title.config';
import { DataTitleProps } from '@components/atoms/visual/components/data-title/data-title.enum';

export class SourceLocationConstant {
  static readonly formSectionList: any[] = [
      SourceLocationSection.SL_INFORMATION,
      SourceLocationSection.PLANT_CODE,
      SourceLocationSection.CV_CODE,
      SourceLocationSection.PRODUCTION_SOURCE
  ];

  static readonly sectionPath = {
    [SourceLocationSection.SL_INFORMATION]: 'sl-information',
    [SourceLocationSection.PLANT_CODE]: 'plant-code',
    [SourceLocationSection.CV_CODE]: 'cv-code',
    [SourceLocationSection.PRODUCTION_SOURCE]: 'production-source'
  };
    
  static readonly sectionList = [
    SourceLocationSection.SL_INFORMATION,
    SourceLocationSection.PLANT_CODE,
    SourceLocationSection.CV_CODE,
    SourceLocationSection.PRODUCTION_SOURCE
  ];
    
  static readonly sectionConfig = {
    [SourceLocationSection.SL_INFORMATION]: {
      [FormSectionProps.ID]: SourceLocationSection.SL_INFORMATION,
      [FormSectionProps.LABEL_KEY]: 'SOURCE_LOCATION.SL_INFORMATION.TITLE',
      [FormSectionProps.PATH]: SourceLocationConstant.sectionPath[SourceLocationSection.SL_INFORMATION]
    },
    [SourceLocationSection.PLANT_CODE]: {
      [FormSectionProps.ID]: SourceLocationSection.PLANT_CODE,
      [FormSectionProps.LABEL_KEY]: 'SOURCE_LOCATION.PLANT_CODE.TITLE',
      [FormSectionProps.PATH]: SourceLocationConstant.sectionPath[SourceLocationSection.PLANT_CODE]
    },
    [SourceLocationSection.CV_CODE]: {
        [FormSectionProps.ID]: SourceLocationSection.PLANT_CODE,
        [FormSectionProps.LABEL_KEY]: 'SOURCE_LOCATION.CV_CODE.TITLE',
        [FormSectionProps.PATH]: SourceLocationConstant.sectionPath[SourceLocationSection.CV_CODE]
    },
    [SourceLocationSection.PRODUCTION_SOURCE]: {
        [FormSectionProps.ID]: SourceLocationSection.PRODUCTION_SOURCE,
        [FormSectionProps.LABEL_KEY]: 'SOURCE_LOCATION.PRODUCTION_SOURCE.TITLE',
        [FormSectionProps.PATH]: SourceLocationConstant.sectionPath[SourceLocationSection.PRODUCTION_SOURCE]
    }
  };

  static readonly tableListConfig: TableField[] = [
    {
      [TableFieldProps.FIELD]: SourceLocationInformationProps.SOURCE_LOCATION_CODE,
      [TableFieldProps.HEADER]: 'SOURCE_LOCATION.SL_INFORMATION.FIELDS.SL_CODE',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: SourceLocationInformationProps.SOURCE_LOCATION_NAME,
      [TableFieldProps.HEADER]: 'SOURCE_LOCATION.SL_INFORMATION.FIELDS.SL_NAME',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: SourceLocationInformationProps.BENEFICIARY_NAME,
      [TableFieldProps.HEADER]: 'SOURCE_LOCATION.SL_INFORMATION.FIELDS.BENEFICIARY_NAME',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: SourceLocationInformationProps.BANK_CODE,
      [TableFieldProps.HEADER]: 'SOURCE_LOCATION.SL_INFORMATION.FIELDS.BENEFICIARY_BANK_CODE',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: SourceLocationInformationProps.BANK_NAME,
      [TableFieldProps.HEADER]: 'SOURCE_LOCATION.SL_INFORMATION.FIELDS.BENEFICIARY_BANK_NAME',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: SourceLocationInformationProps.BANK_IBAN_CODE,
      [TableFieldProps.HEADER]: 'SOURCE_LOCATION.SL_INFORMATION.FIELDS.IBAN_BANK',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: SourceLocationInformationProps.BANK_BIC_CODE,
      [TableFieldProps.HEADER]: 'SOURCE_LOCATION.SL_INFORMATION.FIELDS.BIC_NUMBER',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: sourceLocationBankAccountsProps.BANK_CURRENCY_CODE,
      [TableFieldProps.HEADER]: 'SOURCE_LOCATION.SL_INFORMATION.FIELDS.CURRENCY',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: sourceLocationBankAccountsProps.BANK_COUNTRY_NAME,
      [TableFieldProps.HEADER]: 'SOURCE_LOCATION.SL_INFORMATION.FIELDS.COUNTRY_NAME',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: SourceLocationInformationProps.SL_STATUS,
      [TableFieldProps.HEADER]: 'SOURCE_LOCATION.SL_INFORMATION.FIELDS.SL_STATUS',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: GenericTableColumnProps.ACTIONS,
      [TableFieldProps.HEADER]: 'GENERIC_COLUMNS.VIEW',
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.ACTION,
      [TableFieldProps.ALIGN_BUTTON]: LinkButtonPosition.CENTER,
      [TableFieldProps.WIDTH]: ColumnSize.VERY_SMALL
    }
  ];

  static readonly globalFilter = [SourceLocationInformationProps.SOURCE_LOCATION_NAME];

  static readonly popUpListconfigFirst: DataTitleConfig[] = [{
    [DataTitleProps.PATH]: [SourceLocationInformationProps.FTP_BATCH_CODE],
    [DataTitleProps.TITLE_KEY]: 'SOURCE_LOCATION.SL_INFORMATION.FIELDS.FTP_PROCESSING',
    [TableFieldProps.PIPE_LIST]: [{ pipe: CommonBooleanLabelPipe }]
  },
  {
    [DataTitleProps.PATH]: [SourceLocationInformationProps.FTP_BATCH_CODE],
    [DataTitleProps.TITLE_KEY]: 'SOURCE_LOCATION.SL_INFORMATION.FIELDS.FTP_BATCH_CODE',
    [DataTitleProps.DATA_HIDDEN]: SourceLocationInformationProps.FTP_BATCH_CODE
  }]

  static readonly popUpListconfigSecond: DataTitleConfig[] = [{
    [DataTitleProps.PATH]: [SourceLocationInformationProps.CREATE_PTSL_FILE],
    [DataTitleProps.TITLE_KEY]: 'SOURCE_LOCATION.SL_INFORMATION.FIELDS.CREATE_PTSL_FILE'
  },
  {
    [DataTitleProps.PATH]: [SourceLocationInformationProps.GL_ACCOUNT_NO],
    [DataTitleProps.TITLE_KEY]: 'SOURCE_LOCATION.SL_INFORMATION.FIELDS.GL_OPERATION_LOCATION_NUMBER'
  }]

  static readonly popUpListconfigThird: DataTitleConfig[] = [{
    [DataTitleProps.PATH]: [SourceLocationInformationProps.PRODUCTS_TYPE],
    [DataTitleProps.TITLE_KEY]: 'SOURCE_LOCATION.SL_INFORMATION.FIELDS.PRODUCT_TYPE'
  }];

  static readonly slExtraFilterInitialValues = {
    [SourceLocationInformationFilterProps.SOURCE_LOCATION_CODE]: '',
    [SourceLocationInformationFilterProps.SOURCE_LOCATION_NAME]: '',
    [SourceLocationInformationFilterProps.BENEFICIARY_NAME]: ''
  }
}